/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* BMI Calculator */
body{
  background-image: url("./assets/img/image.png");
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  
  
}
.main-div{
  width: 1150px;
  height: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;;
  border-radius: 10px;
}
.bmi-form{
width: 500px;
height: 450px;
display: flex;
flex-direction: column;
/* justify-content: center; */
padding-top: 50px;
/* background-color: turquoise; */
}
.bmi-result{
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color:thistle; */
}
.mybtn{
  color: white;
  background-color:#3457cd;
  width: 355px;
  height: 50px;
  border: none;
  margin-top: 40px;
}
h4{
  color: #517cc1;
}
.bmi-input{
  width: 340px;
  height: 50px;
  border: none;
  padding-left: 10px;
  background-color: #f0f2f9;
}

.img-container{
  background-color:white;
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.img-container {
  height: 200px;
  width: 200px;
}

.result-img{
  height: 180px;
  width: 180px;

}